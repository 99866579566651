.App {
  text-align: center;
}

.App-logo {
  animation: App-logo-spin infinite 20s linear;
  height: 40vmin;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

.plan-active {
  margin-bottom: 1rem !important;
  padding: 20px 26px !important;
  background: #2D2D2D !important; 
  border: 2px solid #2D2D2D !important; 
  box-sizing: border-box !important;
  border-radius: 5px !important;
}
.plan-active .MuiTypography-body1 {
  color: #FFFFFF !important;
}
@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

@font-face {
  font-family: 'Source Sans Pro';
  src: local('SourceSansPro'), url(./fonts/SourceSansPro/SourceSansPro-Regular.otf) format('opentype');
}

@font-face {
    font-family: 'Source Sans Pro';
    font-weight: 700;
    src: local('SourceSansPro'), url(./fonts/SourceSansPro/SourceSansPro-Bold.otf) format('opentype');
}

@font-face {
    font-family: 'Source Sans Pro';
    font-weight: 900;
    src: local('SourceSansPro'), url(./fonts/SourceSansPro/SourceSansPro-Black.otf) format('opentype');
}


